/* ====== COMMON PROPERTIES ====== */
/* Base alignment classes - apply text and content alignment together */
.align-center, 
.format-p, .format-percentage, .format-n, .format-d, .format-t, .format-g,
.number-format, .percent-format, .date-format, .currency-format, .text-format,
.cell-topLabel, .cell-topGroup, .cell-mergeOrigin,
[colspan], [rowspan] {
    text-align: center !important;
}

/* Center align all data cells */
td:not(.cell-topLabel):not(.cell-sideLabel):not(.cell-topGroup):not(.cell-sideGroup):not(.cell-mergeOrigin) {
    text-align: center !important;
}

td:not(.cell-topLabel):not(.cell-sideLabel):not(.cell-topGroup):not(.cell-sideGroup):not(.cell-mergeOrigin) .cell-content {
    justify-content: center !important;
}

/* Add classes for row and column percentages */
.percentType-row {
    color: #cc0000 !important; /* Red for row percentages */
    text-align: right !important;
}

.percentType-column {
    color: #0066cc !important; /* Blue for column percentages */
    text-align: right !important;
}

.align-center .cell-content,
.format-p .cell-content, .format-percentage .cell-content, 
.format-n .cell-content, .format-d .cell-content, .format-t .cell-content, .format-g .cell-content,
.number-format .cell-content, .percent-format .cell-content, 
.date-format .cell-content, .currency-format .cell-content, .text-format .cell-content,
.cell-topLabel .cell-content, .cell-topGroup .cell-content, .cell-mergeOrigin .cell-content {
    justify-content: center !important;
}

.align-right .cell-content,
.percentType-row .cell-content,
.percentType-column .cell-content {
    justify-content: flex-end !important;
}

.align-left .cell-content {
    justify-content: flex-start !important;
}

/* Special alignment for side labels */
.cell-sideLabel, .cell-sideGroup {
    text-align: left !important;
}

.cell-sideLabel .cell-content {
    justify-content: flex-start !important;
}

.cell-sideGroup .cell-content {
    justify-content: flex-start !important;
}

/* Common numeric format properties */
.number-format, .percent-format, .currency-format {
    font-variant-numeric: tabular-nums !important;
    white-space: nowrap !important;
}

/* Special colors */
.format-p, .format-percentage, .percent-format {
    color: #0066cc !important;
}

/* Override for specific percentage types */
.percentType-row {
    color: #cc0000 !important; /* Red for row percentages */
}

.percentType-column {
    color: #0066cc !important; /* Blue for column percentages */
}

/* Common white-space nowrap */
.date-format, .number-format, .percent-format, .currency-format {
    white-space: nowrap !important;
}

/* ====== TABLE STRUCTURE ====== */
/* Table Base Styles */
.json-table {
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin: 0;
    padding: 0;
    table-layout: fixed !important;
    width: fit-content;
    min-width: 100%;
}

/* Table cells */
.json-table td,
.json-table th {
    border: 1px solid #d0d0d0;
    padding: 4px 8px;
    position: relative;
    height: 32px;
    overflow: hidden;
    max-width: inherit;
    width: inherit;
    box-sizing: border-box;
    transition: border 0.15s ease;
}

/* Cell Content Styles */
.cell-content {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    max-width: 100%;
    width: 100%;
}

.cell-content.nowrap {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cell-content.wrap {
    white-space: normal;
    word-break: break-word;
}

/* ====== CELL HEIGHT CLASSES ====== */
.json-table tr.table-row {
    height: 32px;
    min-height: 32px;
}

.json-table tr[data-height="19"] {
    height: 19px;
    min-height: 19px;
}

.json-table tr[data-height="20"] {
    height: 20px;
    min-height: 20px;
}

.json-table tr[data-height="24"] {
    height: 24px;
    min-height: 24px;
}

.json-table tr[data-height="32"] {
    height: 32px;
    min-height: 32px;
}

.json-table tr[data-height="40"] {
    height: 40px;
    min-height: 40px;
}

/* ====== SPECIAL CELL TYPES ====== */
/* Empty cells */
.empty-cell,
.json-table tr td:first-child.empty-cell {
    background-color: #fafafa;
    width: 40px !important;
    max-width: 40px !important;
    padding: 0 !important;
}

/* Label cells */
.cell-topLabel, .cell-sideLabel {
    font-weight: 600;
    background-color: #f5f5f5 !important;
    border: 1px solid #aaa !important;
}

.cell-topGroup, .cell-sideGroup {
    font-weight: 600;
    background-color: #e0e0e0 !important;
}

/* Label text */
.cell-label-text {
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Merge origin */
.cell-mergeOrigin {
    font-weight: 700;
    background-color: #f5f5f5 !important;
    border-right: 2px solid #aaa !important;
    border-bottom: 2px solid #aaa !important;
    z-index: 1;
    position: relative;
}

/* ====== COLUMN DEFINITIONS ====== */
/* Side label column */
.json-table colgroup col.side-label-col {
    width: 140px !important;
    max-width: 140px !important;
    transition: min-width 0.3s ease, width 0.3s ease, max-width 0.3s ease !important;
}

/* Empty column */
.json-table colgroup col.empty-col {
    max-width: 40px !important;
}

/* Standard table column */
.json-table colgroup col.table-col {
    width: 150px !important;
    max-width: 150px !important;
}

/* ====== EXPANDED STATE ====== */
/* Table in expanded state */
.side-expanded .json-table {
    width: fit-content;
    min-width: 100%;
}

/* Expanded side column */
.side-expanded .json-table colgroup col.side-label-col {
    width: 320px !important;
    max-width: 320px !important;
}

/* Ensure empty columns stay narrow in expanded state */
.side-expanded .json-table colgroup col.empty-col,
.side-expanded .json-table td:first-child.empty-cell,
.side-expanded .json-table td.empty-cell,
.side-expanded .json-table tr td:first-child.empty-cell {
    max-width: 40px !important;
    padding: 0 !important;
}

/* Expanded side label cells */
.side-expanded .json-table td.cell-sideLabel,
.side-expanded .json-table td.cell-sideGroup,
.json-table td.side-expanded-cell {
    width: 320px !important;
    max-width: 320px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
}

/* Expanded cell content overflow handling */
.side-expanded .cell-content,
.side-expanded .json-table td,
.json-table td.side-expanded-cell .cell-content {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

/* Ensure table container can scroll horizontally */
.side-expanded .table-container > div {
    overflow-x: auto;
    width: auto;
    min-width: 100%;
}

/* ====== INTERACTIVE ELEMENTS ====== */
/* Hovered cells */
.json-table td.hovered {
    box-shadow: 0 0 0 2px #2196f3;
    z-index: 5;
}

/* Search match highlighting */
.json-table tr.current-match-row {
    background-color: rgba(255, 213, 79, 0.2);
}

.search-match {
    background-color: rgba(255, 236, 179, 0.7) !important;
    outline: 2px solid #ffd54f !important;
}

.search-match-current {
    background-color: rgba(255, 193, 7, 0.8) !important;
    outline: 2px solid #ff9800 !important;
    box-shadow: inset 0 0 0 1px rgba(255, 152, 0, 0.5) !important;
}

/* ====== CONTAINER AND SCROLLBAR ====== */
/* Table container */
.table-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    position: relative;
    overflow: hidden;
}

.table-container > div {
    min-width: 0;
    width: 100%;
}

/* Scrollbar styles */
.table-container ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.table-container ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.table-container ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.table-container ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.table-container ::-webkit-scrollbar-corner {
    background: #f1f1f1;
} 